import React, { useState } from "react";
import { useQueryParam, StringParam } from "use-query-params";

import SubpageHeader from "components/SubpageHeader";
import NewsCard from "components/NewsCard";
import Divider from "components/Divider";

import { usePosts } from "context/posts";

const Content = () => {
  const { posts } = usePosts();
  const [queryParam] = useQueryParam("query", StringParam);
  const [query, setQuery] = useState(queryParam ? queryParam : "");

  return (
    <>
      <SubpageHeader title={`Blog - wynik wyszukiwania: ${query}`} />
      <section className="blog-main">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-9">
              <div className="blog-main__search">
                <div className="row align-items-center">
                  <div className="col-md">
                    <p>Wpisz szukaną frazę:</p>
                  </div>
                  <div className="col-md-6">
                    <input
                      type="text"
                      name="search"
                      onChange={(e) => setQuery(e.target.value)}
                      required
                    />
                  </div>
                  <div className="col-md">
                    <button type="submit" className="button button--green">
                      Szukaj
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Divider marginTop={45} marginBottom={45} />

          <div className="blog-main__posts">
            {posts
              ?.filter(
                (item) =>
                  item.title.includes(query) ||
                  item.content.includes(query) ||
                  item.title.toLowerCase().includes(query)
              )
              .map((item, index) => (
                <NewsCard
                  key={index}
                  title={item.title}
                  excerpt={item.excerpt}
                  date={item.date}
                  update={item?.acfPost?.postUpdateDate}
                  url={item.uri}
                  thumbnail={item.featuredImage?.node.sourceUrl}
                  authorAvatar={item.author.node.avatar.url}
                  authorName={`${item.author.node.firstName} ${item.author.node.lastName}`}
                  categories={item.categories.nodes}
                />
              ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Content;
